import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const TextEditor = ({ text, setText }) => {
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setText(data);
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      data={text}
      onChange={handleEditorChange}
      config={{
        placeholder: "Start typing here...",
      }}
    />
  );
};

export default TextEditor;
