import React, { useEffect, useState } from "react";

const TextByLetter = ({ text, speed, look }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text[index]);
        index++;
      } else {
        clearInterval(interval); // Stop the interval once the text is fully displayed
      }
    }, speed);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [text, speed]);

  return (
    <>
      {look === "title" ? (
        <h1 className="text-primary">{displayedText}</h1>
      ) : "subtitle" ? (
        <h3 className="text-primary">{displayedText}</h3>
      ) : (
        <p className="lead">{displayedText}</p>
      )}
    </>
  );
};

export default TextByLetter;
