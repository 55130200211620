import React, { useEffect, useState } from "react";
import {
  ListGroupItem,
  Row,
  Col,
  Button,
  FormGroup,
  FormLabel,
  Container,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Message from "./Message";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import Skeleton from "react-loading-skeleton";
import InpatientList from "./InpatientList";
// import { useNavigate } from "react-router-dom";

const PatientList = ({
  setShowInpatients,
  showInpatients,
  patientListVehicle,
  teamId,
  setPatientListVehicle,
}) => {
  const [message, setMessage] = useState("");

  const newPatientStore = useSelector((state) => state.newPatientStore);
  const { error: registerError, loading: loadingRegister } = newPatientStore;

  const patientListStore = useSelector((state) => state.patientListStore);
  const { loading, error, patients, page } = patientListStore;

  if (error) {
    setMessage(error);
  }

  // const navigate = useNavigate();

  useEffect(() => {
    if (patients) {
      if (page) {
        if (page === 1) {
          setPatientListVehicle(patients);
        } else {
          setPatientListVehicle(patientListVehicle.concat(patients));
        }
      }
    }
  }, [page, patients]);

  return (
    <>
      {registerError && (
        <Message variant="danger" setMessgae={setMessage}>
          {registerError}
        </Message>
      )}

      {message && (
        <Message variant="danger" setMessgae={setMessage}>
          {message}
        </Message>
      )}

      <Container>
        <Row>
          <FormGroup className="col">
            <FormCheckInput
              checked={showInpatients}
              onChange={() => setShowInpatients(!showInpatients)}
              type="checkbox"
            ></FormCheckInput>{" "}
            <FormLabel>Stop Search and Show Inpatients Only.</FormLabel>
          </FormGroup>
        </Row>
      </Container>

      {loading && <Skeleton height="75px" className="mb-1" count={3} />}
      {loadingRegister && <Skeleton height="75px" className="mb-1" count={3} />}

      {showInpatients ? (
        <InpatientList teamId={teamId} />
      ) : (
        <div className="mt-5">
          {patientListVehicle &&
            patientListVehicle.map((patient) => (
              <div key={patient._id}>
                <div
                  onClick={() => {
                    // navigate(`/patient/${patient._id}`);
                    window.open(
                      `https://www.clinicbook.org/patient/${patient._id}`,
                      "_blank"
                    );
                  }}
                >
                  <ListGroupItem className="d-flex justify-content-between align-items-center border-primary">
                    <Col>
                      <Row className="justify-content-end">
                        <h3 className="row flex-row-reverse">
                          <span className="left-text">
                            {`${patient.firstName} ${patient.middleName} ${patient.lastName} ${patient.fourthName}`}
                          </span>
                        </h3>
                        <br />
                      </Row>
                      <h4 className="row justify-content-end">
                        تاريخ الميلاد: {patient.birthdate.substring(0, 10)}
                      </h4>
                      <div className="btn btn-outline-primary">Show</div>
                    </Col>
                  </ListGroupItem>
                </div>
                &nbsp;
              </div>
            ))}
        </div>
      )}
      {loading && <Skeleton height="75px" className="mb-1" count={2} />}
    </>
  );
};

export default PatientList;
