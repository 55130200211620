import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import { useLocation, useNavigate } from "react-router-dom";
import Counter from "../components/Counter";
import PasswordChecker from "../components/PasswordChecker";
import TextEditor from "../components/TextEditor";

const UserProfile = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [about, setAbout] = useState("");
  const [title, setTitle] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState();
  const [reviews, setReviews] = useState();
  const [patients, setPatients] = useState();
  const [start, setStart] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const updatedUserDetails = useSelector((state) => state.updatedUserDetails);
  const { user: updatedUser, loading, error } = updatedUserDetails;

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      setMessage("");
      dispatch(
        updateUserProfile({
          firstName,
          lastName,
          email,
          password,
          phone,
          title,
          about,
          recommendations,
        })
      );
    }
  };

  const acceptRecomm = (r) => {
    r.isAccepted = !r.isAccepted;
    dispatch(
      updateUserProfile({
        firstName,
        lastName,
        email,
        password,
        phone,
        title,
        about,
        recommendations,
      })
    );
  };

  useEffect(() => {
    if (!userInfo) {
      navigate(`/?returnFrom=${location.pathname}`);
    }
    if (updatedUser) {
      dispatch({ type: USER_DETAILS_RESET });
    }

    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setPhone(user.phone);
      setAbout(user.about ? user.about : "");
      setReviews(user.reviews);
      setPatients(user.patients);
      setStart(user.start && user.start.substring(0, 10));
      setTitle(user.title);
      setRecommendations(user.recommendations);
    } else {
      if (userInfo) {
        dispatch(getUserDetails(userInfo._id));
      }
    }
  }, [dispatch, userInfo, user, loading, updatedUser, navigate]);

  return (
    <FormContainer>
      <BreadcrumbComponent
        titles={[
          { idx: "1", label: "Home", path: `/`, active: false },
          { idx: "2", label: "Profile Screen", path: ``, active: true },
        ]}
      />

      {message && <Message variant="danger">{message}</Message>}
      {!user ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <h3>{`${firstName} ${lastName}`}</h3>{" "}
          <strong className="row justify-content-center">
            {start && `Start reviewing: ${start}`}
          </strong>
          <Row>
            <Col className="col-lg-6">
              <Counter limit={patients} label="Patients" />
            </Col>
            <Col className="col-lg-6">
              <Counter limit={reviews} label="Reviews" />
            </Col>
          </Row>
          <h3>Your Profile</h3>
          <>
            <Form onSubmit={submitHandler}>
              <Row>
                <Col>
                  <Form.Group controlId="firstName">
                    <Form.Control
                      type="name"
                      placeholder="Enter first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="lastName">
                    <Form.Control
                      type="name"
                      placeholder="Enter last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  readOnly
                  value={email}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>Whatsapp Phone </Form.Label>
                <Form.Control
                  type="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="textarea"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="about">
                <Form.Label>About</Form.Label>
                <TextEditor text={about} setText={setAbout} />
              </Form.Group>

              <h3>Recommendations Management</h3>

              {recommendations &&
                recommendations.map((r) => (
                  <Card
                    className="m-3"
                    style={{
                      textDecorationLine: !r.isAccepted && "line-through",
                    }}
                    key={r._id}
                  >
                    <Card.Header>{r.recommenderName}</Card.Header>
                    <Card.Body>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${r.recommendation}`,
                        }}
                      />
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        style={{ float: "right" }}
                        onClick={() => acceptRecomm(r)}
                      >
                        {r.isAccepted ? "Hide" : "Publish"}
                      </Button>
                    </Card.Footer>
                  </Card>
                ))}

              {userInfo && (
                <Message
                  children={`Your profile is public on https://www.clinicbook.org/user/${userInfo._id}`}
                  variant="info"
                />
              )}

              <h1>Change Password</h1>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter New Password"
                  value={password}
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <PasswordChecker password={password} />

              <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  autoComplete="new-password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button
                className="btn btn-info"
                style={{ float: "right" }}
                type="submit"
              >
                Update Details
              </Button>
            </Form>
            <i className="fa fa-whatsapp" aria-hidden="true"></i>
          </>
        </>
      )}
    </FormContainer>
  );
};

export default UserProfile;
