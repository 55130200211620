import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Message from "./Message";
import { useDispatch, useSelector } from "react-redux";
import { listInpatient } from "../actions/patientActions";
import { Col, ListGroupItem, Row, Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

const InpatientList = ({ showInpatients, teamId }) => {
  const inpatientListStore = useSelector((state) => state.inpatientListStore);
  const { noRequest, loading, inpatients, team, error } = inpatientListStore;

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  useEffect(() => {
    if (noRequest || team !== teamId) {
      dispatch(listInpatient(teamId));
    }
  }, [showInpatients, teamId, team]);

  return (
    <>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Skeleton height="150px" className="my-2" count={6} />}
      {inpatients && (
        <div className="mt-5">
          {inpatients &&
            inpatients.map((inpatient) => (
              <div key={inpatient._id}>
                <div
                  onClick={() => {
                    // navigate(`/patient/${inpatient._id}`);
                    window.open(
                      `https://www.clinicbook.org/patient/${inpatient._id}`,
                      "_blank"
                    );
                  }}
                >
                  <ListGroupItem className="d-flex justify-content-between align-items-center border-primary">
                    <Col>
                      <Row className="justify-content-end">
                        <h3 className="row flex-row-reverse">
                          <span>
                            {`${inpatient.firstName} ${inpatient.middleName} ${inpatient.lastName} ${inpatient.fourthName}`}
                          </span>
                        </h3>
                        <br />
                      </Row>
                      <h4 className="row justify-content-end">
                        تاريخ الميلاد: {inpatient.birthdate.substring(0, 10)}
                      </h4>
                      <Button className="btn btn-info">Show</Button>
                    </Col>
                    <Col className="col-sm-1 d-flex align-self-start">
                      <span className="badge bg-primary rounded-pill text-white">
                        {inpatient.order}
                      </span>
                    </Col>
                  </ListGroupItem>
                </div>
                &nbsp;
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default InpatientList;
